import {defineStore} from "pinia"
import axios from 'axios'
import {toRaw} from 'vue'

import { ExactorderModel as ExactorderModel} from "../models/ExactorderModel"

export const useExactStore = defineStore("exact", {
    state: () => ({
        orders: {}, // dict of all selected orders
        ordered: [], // array with only they keys of selected orders sorted by desired production order
        // test: 'abc',
        // fields: null,
        // order: null,
        // ordernr: null,
        // line: null,
        // state: null,
        // status: null
    }),
    persist: true,
    getters: {},
    actions: {
        clear() {
            this.orders= {}
            this.ordered= []
        },
        delete(i) {
            const o = this.ordered[i]
            delete this.orders[o]
            this.ordered = Object.keys(this.orders)
        },
        countOrders() {
            return this.ordered.length
        },
        reload() { // pinia is not meant for keeping complex objects,  so after reload restore the objects, https://github.com/vuejs/pinia/discussions/1178
            for ( let o in this.orders ) {
                const p =  this.orders[o]
                if (typeof p=='object' ) {
                    let m = new ExactorderModel(p.ordernr,p.line)
                    m.setProperties(p.status,p.fields)
                    m.setState(p.state)
                    this.orders[o] = m
                }
            }
        },
        total() {
            let t = 0
            for ( let o in this.orders ) {
                if (this.orders[o].state ) {
                    t += this.orders[o].state.total
                }
            }
            return t
        },
        async retrieveOrder(o, l, token) {
            const ordernr = parseInt(o)
            const line = parseInt(l)
            let m = new ExactorderModel(ordernr,line)
            try {
                const response = await axios.request({
                    url: "/api/exact_orders/" + m.order,
                    headers: {Authorization: `Bearer ${token}`},
                    maxRedirects: 0 // this only works in nodejs, not in browser
                })

                if (response.status !== 200 || (response.data.fields.status !== 200 && response.data.fields.status !== 304)) {
                    if ( response.data && response.data.fields && response.data.fields.status ) {
                        m.setProperties(response.data.fields.status, {
                            status: response.data.fields.status,
                            error: response.data.fields.error
                        })
                    } else {
                        m.setProperties(500, {
                            status: 500,
                            error: 500
                        })
                    }
                    return m
                }
                m.setProperties(200,response.data.fields)
            } catch (err) {
                m.setProperties(err.response,{status:500,error:'exception'})
            }
            // this.orders = {}
            this.orders[m.order] = m
            if (!this.ordered.includes(m.order)) {
                this.ordered.push(m.order)
            }
            if ( this.ordered.length !== Object.keys(this.orders).length ) {
                this.ordered = Object.keys(this.orders)
            }
            return m
        },
        async retrieveOrderStatus(o, l, token) {
            const ordernr = parseInt(o)
            const line = parseInt(l)
            let m = this.orders[ordernr+'-'+line]
            if ( m ) {
                try {
                    const response = await axios.get("/api/order_states/" + o + '-' + l, {headers: {Authorization: `Bearer ${token}`}})
                    if (response.status !== 200) {
                        m.setState(null)
                    } else {
                        m.setState(response.data)
                    }
                } catch (err) {
                    console.log(err)
                    if (m) {
                        m.status = err.status
                    }
                }
            }
        },
    }
});
