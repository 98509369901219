<template>
  <div class="item2">
    Voer de order in en druk op "Ophalen". <br>Het regelnummer is in het algemeen 1.
    <FormKit type="form"
             :actions="exactStore.total()<15"
             submit-label="Ophalen"
             @submit="retrieveOrder"
             :submit-attrs="{
          // help: 'Exact gegevens ophalen',
        }">
      <FormKit
          type="text"
          name="ordernr"
          v-model="ordernr"
          label="Exact order"
          placeholder="###"
          validation="required"
      />
      <FormKit
          type="text"
          value="1"
          name="line"
          label="Exact regelnummer"
          placeholder="##"
          validation="required"
      />
    </FormKit>
    Het totaal van de  {{ exactStore.countOrders() }} orders is nu {{ exactStore.total() }}.
    <div v-if="validAdd() && exactStore.total()<15 && exactStore.total()>0">
      Er kan eventueel aangevuld worden met een extra order. Vul die hierboven in en kies ophalen.
    </div>
    <div v-if="exactStore.total()>15 && exactStore.countOrders()>1" style="color:red">
      Een samengestelde pallet mag niet meer dan 15 IVT's bevatten. Verwijder een order.
    </div>
    <div v-if="!combi">
      <div v-if="!validAdd()" style="color:red">
        Een al gestarte order mag niet gecombineerd worden. Verwijder de al gestarte order.
      </div>
    </div>
    <br>
    Alle gekozen orders verwijderen: <font-awesome-icon @click="clear()" :icon="['fas', 'trash']"/>

  </div>
  <div class="item3">
    <div v-for="(o, i) in  exactStore.ordered">
      <div v-if="exactStore.orders[o].fields &&  exactStore.orders[o].state">
        <font-awesome-icon @click="del(i)" :icon="['fas', 'trash']"/>&nbsp;
        &nbsp;<font-awesome-icon v-if="i!==0" @click="up(i)" :icon="['fas', 'arrow-up']"/>
        &nbsp;<font-awesome-icon v-if="i!==(exactStore.ordered.length-1)"  @click="down(i)" :icon="['fas', 'arrow-down']"/>&nbsp;
        Order: {{ exactStore.orders[o].ordernr }}-{{ exactStore.orders[o].line }}
        &nbsp;&nbsp;Datum: {{ exactStore.orders[o].fields.Besteldatum }}
        <pre>     Order grootte: {{ exactStore.orders[o].state.total }} armaturen
     Armaturen gelabeld: {{ exactStore.orders[o].state.done }}
    <span class="error" v-if="exactStore.orders[o].state.done>=exactStore.orders[o].state.total">LET OP: order is compleet
    <span v-if="exactStore.ordered.length===1">Pallets gereed: {{ exactStore.orders[o].state.palletstotal }}</span></span>
    <span v-if="exactStore.orders[o].state.done<exactStore.orders[o].state.total && exactStore.ordered.length===1">Werken aan pallet: {{ exactStore.orders[o].state.currentpallet }} van {{ exactStore.orders[o].state.palletstotal }}
    Pallets gereed: {{ exactStore.orders[o].state.currentpallet - 1 }}</span></pre>
      </div>
    </div>

    <!-- only if we have a multi order less then 15 or if single order we can continue -->
    <FormKit
        v-if="validAdd() || combi"
        type="button"
        label="Start"
        help="begin met assembleren"
        @click="next"
    />
  </div>


</template>

<script>
//  vs script setup with onMounted(() =
import {toRaw} from 'vue'
import {useAuthStore} from "../stores/AuthStore";
import {useExactStore} from "../stores/ExactStore";
import {useRouter} from 'vue-router';
import {inject, ref} from "vue";

export default {
  name: "Flv",
  // inject: ['message'],
  // created() {
  //     console.log('MSG')
  //     console.log(this.message) // injected value
  //     this.message = 'haha'
  // },
  setup() {
    const authStore = useAuthStore();
    const exactStore = useExactStore();
    const router = useRouter()
    const headerSubtitle = inject("headerSubtitle", ref(-1));
    return {
      authStore, exactStore, router, headerSubtitle,
      setHeaderSubtitle(t) {
        headerSubtitle.value = t
      }
    };
  },
  data() {
    return {
      content: "",
      ordernr: "",
      combi: false
    };
  },
  async beforeCreate() {
    const status = await this.authStore.check()
    if (!status) {
      this.$router.push("/login");
    }
    this.exactStore.reload()
  },
  async mounted() {
    if (import.meta.env.DEV) {
      this.ordernr = 7544
    }
    if (this.exactStore.countOrders()>1) {
      this.combi = true
    }

    this.setHeaderSubtitle('Voer de exact order in en klik ophalen')


  },
  methods: {
    validAdd() {
      if ( this.exactStore.total() === 0 ) {
        return true
      }
      if (!(this.exactStore.total() > 0 && this.exactStore.total() <= 15 || this.exactStore.countOrders() === 1)) {
        return false
      }

      if ( this.exactStore.ordered.length>1 ) {
        for (let o of this.exactStore.ordered) {
          if (this.exactStore.orders[o].state && this.exactStore.orders[o].state.done>=1) {
            return false;
          }
        }
      }
      return true
    },
    swapElements (array, index1, index2) {
      let temp = array[index1];
      array[index1] = array[index2];
      array[index2] = temp;
    },
    clear() {
      this.exactStore.clear()
    },
    up(i) {
      this.swapElements(this.exactStore.ordered,i,i-1)
    },
    down(i) {
      this.swapElements(this.exactStore.ordered,i,i+1)
    },
    del(i) {
      this.exactStore.delete(i)
    },
    next() {
      this.$router.push("/assemble");
    },
    async retrieveOrder(order, node) {
      let x = await this.exactStore.retrieveOrder(order.ordernr, order.line, this.authStore.token)
      if ( (x.status !== 200 && x.status !== 304) ) {
        node.setErrors(
            // Arg 1 is form-wide errors
            ['There was an error retrieving the order from exact'+x.status],
            // // Arg 2 is field-specific errors
            // {
            //   username: 'Sorry, that username is not available.',
            // }
        )
        // if (exactStore.status === 401) {
        //   authStore.logout()
        //   router.push('/login');
        // }
      } else {
        node.setErrors([]);
        this.setHeaderSubtitle('Controleer de order en druk op start')
      }

      await this.exactStore.retrieveOrderStatus(order.ordernr, order.line, this.authStore.token)
      let combi = this.exactStore.orders[order.ordernr+'-'+order.line].state.combi
      if ( combi ) {
        this.combi = true
        this.clear()
        let orders = combi.split(',')
        for (const o of orders) {
          let nrln = o.split('-')
          await this.exactStore.retrieveOrder(nrln[0], nrln[1], this.authStore.token)
          await this.exactStore.retrieveOrderStatus(nrln[0], nrln[1], this.authStore.token)
        }
      } else {
        this.combi = false
      }

    }
  }
};
</script>

<style>
button, a {
  margin: 10px;
  padding: 10px;
  font-size: large;
}


</style>
